@font-face {
    font-family: Roboto;
    src: url('./fonts/Roboto-Regular.ttf');
}

.App {
    text-align: center;
}

.Toastify {
    z-index: 999999 !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 5s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

html {
    background-color: #000;
}

body {
    min-height: 100%;
    background-color: #795548;
    font-family: 'Roboto', Arial, sans-serif !important;
}

.page-header {
    height: 60px;
}

.page-footer {
    height: 40px;
}

.page-content {
    width: 100%;
    margin-top: 60px;
    /*height: calc(100vh - 100px);*/
    overflow: auto;
    overflow-scrolling: auto;
    background-image: url("imgs/hero-bg.jpg");
}

.table-no-padding td {
    padding: 0 !important;
}

.modal-custom-width {
    max-width: none !important;
}

.xxl-modal {
    width: 90vw;
    max-width: 90vw;
}

.w-600px {
    width: 600px !important;
}

.w-400px {
    width: 400px !important;
}

.w-480px {
    width: 480px !important;
}

.w-240px {
    width: 240px !important;
}

.w-280px {
    width: 280px !important;
}

.w-300px {
    width: 300px !important;
}

.w-340px {
    width: 340px !important;
}

.w-180px {
    width: 180px !important;
}

.w-170px {
    width: 170px !important;
}

.w-160px {
    width: 160px !important;
}

.w-140px {
    width: 140px !important;
}

.w-120px {
    width: 120px !important;
}

.w-100px {
    width: 100px !important;
}

.w-110px {
    width: 110px !important;
}

.w-35px {
    width: 35px !important;
}

.w-50px {
    width: 50px !important;
}

.mw-50 {
    max-width: 50% !important;
}

.mw-30 {
    max-width: 30% !important;
}


.lh-2em {
    line-height: 2em !important;
}

.lh-2-5em {
    line-height: 2.5em !important;
}

.react-datepicker-wrapper {
    min-width: 120px !important;
}

.table-no-padding .form-control {
    margin-bottom: 0.25em;
}

.min-width-50px {
    min-width: 50px !important;
}

.min-width-450px {
    min-width: 450px !important;
}

.pointer {
    cursor: pointer !important;
}

.Toastify__toast-container {
    width: 480px !important;
}

#table-oven-temps input {
    text-align: right;
    padding-right: 0.25rem !important;
}

.modal-full-size .modal-dialog {
    width: 100% !important;
    max-width: none !important;
}

.modal-50 .modal-dialog {
    width: 50% !important;
    max-width: none !important;
}

.modal-90 .modal-dialog {
    width: 90% !important;
    max-width: none !important;
}

@media print {
    .btn {
        display: none !important;
    }

    td {
        padding: 0 !important;
        margin: 0 !important;
    }

}

.tabs-right-align > .nav-tabs > li, .nav-pills > li {
    float: none;
    display: inline-block;
    zoom: 1;
}

.tabs-right-align > .nav-tabs, .nav-pills {
    text-align: right;
}

.w-16 {
    width: 16%;
}

.w-20 {
    width: 20%;
}

.daily-full-report td {
    padding-right: 3px !important;
    vertical-align: middle !important;
}

.daily-full-report td.align-top {
    vertical-align: top !important;
}

.daily-full-report td.align-bottom {
    vertical-align: bottom !important;
}

.daily-full-report td.no-padding {
    padding: 0 !important;
}

.daily-full-report input:not(input.form-control) {
    max-width: 120px;
    text-align: end;
    padding: 0;
    margin: 0 !important;
}

.daily-full-report .casa input {
    max-width: 100%;
    text-align: start;
}

.daily-full-report .casa input .text-end {
    text-align: end;
}

.production-report-legenda {
    display: block;
    position: absolute;
    top: 70px;
    right: 30px;
    width: 280px;
    height: 280px;
    overflow: hidden;
    background-color: white;
    border: solid 1px silver;
    box-shadow: 1px 1px 1px 1px gray;
    font-size: small;
    padding: 12px;
    opacity: 0.25;
}

.production-report-legenda:hover {
    opacity: 1;
}

td.green {
    background-color: green;
}

td.red {
    background-color: red;
}

td.silver {
    background-color: silver;
}


.print {
    display: none;
}

tr.table-filter-row {
    height: 50px !important;
    background-color: chocolate;
}

tr.table-filter-row > td {
    vertical-align: middle !important;
    padding: 2px !important;
    margin-bottom: 2px !important;
}

.bg-primary-light {
    background-color: rgba(0, 123, 255, 0.25) !important
}

.bg-secondary-light {
    background-color: rgba(123, 123, 123, 0.25) !important
}

.cursor-pointer {
    cursor: pointer;
}


#tableForm76 {
    overflow-y: auto;
    height: 45vh; /* !!!  HEIGHT MUST BE IN [ vh ] !!! */
}

#tableForm76 > thead, #tableForm76 > tr {
    position: sticky;
    top: -1px;
    background-color: silver;
}

@media print {
    * {
        overflow: visible !important;
    }

    @page {
        /*size: auto !important;*/
        size: A4;
    }

    body {
        /*font-size: 75% !important;*/
    }

    button {
        display: none;
    }

    h2 {
        font-size: 1.5em;
        text-align: left;
    }

    .no-print {
        display: none !important;
    }

    .print {
        display: block !important;
    }

    .card {
        border: none !important;
    }

    print-no-shadow {
        box-shadow: none !important;
    }

    .col {
        /*padding: 0 !important;*/
    }

    .card-header {
        padding: 0 !important;
        border: none !important;
        box-shadow: none !important;
    }

    .card-body {
        padding: 0 !important;
        border: none !important;
        box-shadow: none !important;
    }

    .card-footer {
        padding: 0 !important;
        border: none !important;
        box-shadow: none !important;
    }

    .card-subtitle {
        /*padding: 0 !important;*/
    }

    table {
        margin: 0 !important;
    }

    th, td {
        padding: 0 !important;
    }

    .daily-full-report td {
        padding-right: 0px !important;
    }

    input {
        border: none !important;
    }
}

.nav-link.active {
    background-color: #0d6efd !important;
    color: white !important;
}


.blink {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.status-table {
    border-radius: 10px;
}

.status-table td {
    vertical-align: top;
    font-size: 0.75rem;
    min-width: 15%;
    white-space: nowrap;
    width: 15%;
}

.status-table th {
    vertical-align: top;
    font-size: 1rem;
}

.bg-c-yellow {
    background: linear-gradient(0deg, rgba(255, 223, 158, .90), rgba(255, 203, 128, 1));
}

.full-report {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.media-screen-header-item {
    width: 100%;
    background: linear-gradient(0deg, rgba(187, 51, 44, .25), rgba(187, 51, 44, .5));
    color: silver;
    line-height: 2rem;
    text-align: center;
    font-weight: bold;
    border-top-left-radius: 15px;
}

.media-screen-header-item-active {
    line-height: 3rem;
    background: linear-gradient(0deg, rgba(187, 51, 44, .75), rgba(187, 51, 44, 1));
    color: white;
    width: 520px;
}

.media-screen-card {
    display: block;
    width: 100%;
    /*max-height: 270px;*/
    overflow: hidden;
    font-size: 1.25em;
    margin-bottom: 15px;;
}

#employees-screen.media-screen-card {
    height: 950px;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
}

.media-screen-card-outer {
    border: solid 3px white;
    border-radius: 5px;
    padding: 5px;
}

.media-screen-card-innner {
    border: 3px solid rgb(187, 51, 44);
    border-radius: 5px;
    padding: 5px;
    color: white;
}

.media-screen-card-content {
    padding: 5px;
    color: white;
    font-size: 0.75em;
}

.media-screen-card-content-header {
    font-weight: bold;
    color: white;
    font-size: 1em;
    margin-bottom: 10px;
    border-bottom: 2px solid rgb(187, 51, 44);
    padding-bottom: 5px;
}

.media-screen-card-content-footer {
    color: white;
    font-size: 1em;
    margin-top: 10px;
    border-top: 2px solid rgb(187, 51, 44);
    padding-top: 5px;
}

/*==============================*/


.media-department-user-card {
    max-width: 350px !important;
    overflow: hidden;
    text-align: center;
    transition: all 5s;
}

.media-department-user-image {
    height: 300px;
    transition: height 5s;
}
.media-department-user-name {
    display: block;
    font-size: 1.5rem;
    transition: font-size 5s;
}
.media-department-user-data {
    display: block;
    font-size: 1rem;
    opacity: 1;
    transition: all 5s;
}



.media-department-prev {
    position: absolute;
    overflow: hidden !important;
    top: 0;
    overflow: auto;
    width: 100%;
    height: 270px;
    padding: 1rem;
    transition: all 5s ;
    text-align: center;
    opacity: 0.5;
}
.media-department-prev h1 {
    font-size: 1.5rem;
    transition: font-size 5s;
}
.media-department-prev .media-department-user-image {
    height: 100px;
}
.media-department-prev .media-department-user-name {
    font-size: 1rem;
}
.media-department-prev .media-department-user-data {
    opacity: 0;
}
.media-department-prev .media-department-user-card {
    max-width: 200px !important;
    overflow: hidden;
}



.media-department-current {
    position: absolute;
    top: 270px;
    width: 100%;
    height: 1000px;
    padding: 1rem;
    transition: all 5s ;
    text-align: center;
}
.media-department-current .media-department-user-image {
    height: 300px;
}
.media-department-current h1 {
    font-size: 2.5rem;
    transition: font-size 5s;
}
.media-department-current .media-department-user-name {
    font-size: 1.5rem;
}
.media-department-current .media-department-user-data {
   font-size: 1rem;
}
.media-department-current .media-department-user-card {
    max-width: 300px !important;
    overflow: hidden;
}





.media-department-next {
    position: absolute;
    top: 1220px;
    width: 100%;
    height: 227px;
    padding: 1rem;
    transition: all 5s ;
    opacity: 0.5;
}
.media-department-next .media-department-user-image {
    height: 100px;
}
.media-department-next h1 {
    font-size: 1.5rem;
}
.media-department-next .media-department-user-name {
    font-size: 1rem;
}
.media-department-next .media-department-user-data {
    opacity: 0;
}
.media-department-next .media-department-user-card {
    max-width: 200px !important;
    overflow: hidden;
}








.media-department-out {
    position: absolute;
    top: -700px;
    width: 100%;
    height: 227px;
    padding: 1rem;
    transition: all 5s ;
}
.media-department-out h1 {
    font-size: 1.5rem;
}
.media-department-out .media-department-user-image {
    height: 100px;
}
.media-department-out .media-department-user-name {
    font-size: 1rem;
}
.media-department-out .media-department-user-data {
    opacity: 0;
}
.media-department-out .media-department-user-card {
    max-width: 200px !important;
    overflow: hidden;
}



.media-department-in {
    position: absolute;
    top: 1227px;
    width: 100%;
    height: 227px;
    padding: 1rem;
    transition: all 5s ;
}







.media-department-wait {
    position: absolute;
    top: 1500px;
    width: 100%;
    height: 227px;
    padding: 1rem;
    transition: all 5s ;
    opacity: 0;
}
.media-department-wait .media-department-user-image {
    height: 100px;
}
.media-department-wait .media-department-user-name {
    font-size: 1rem;
}
.media-department-wait .media-department-user-data {
    display: none;
}
.media-department-wait .media-department-user-card {

    max-width: 200px !important;
    overflow: hidden;
}












.media-department-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    border-bottom: solid white 2px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
